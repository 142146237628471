<template>
    <v-dialog v-model="GET_MODAL_STATUS.wheatmap" fullscreen hide-overlay transition="dialog-bottom-transition" @keydown.esc="close()" @keydown.enter="close()">
        <div class="dialog-box-inner">
            <div class="filter-area map" v-if="WIRELESS_HEATMAP_READY">
                <div class="filter-duration left">
                    <v-select v-model="selectedDuration" :items="GET_DURATION" item-text="name" item-value="id" label="Select a duration" v-on:change="changeDuration()"></v-select>
                </div>
                <div class="filter-range left" v-if="selectRangeBox">
                    <v-menu ref="dateRangeSelector" v-model="dateRangeSelector" :close-on-content-click="false" :return-value.sync="dates" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateRangeText" label="Select date range" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" ></v-text-field>
                        </template>
                        <v-date-picker v-model="dates" no-title range show-adjacent-months :max="nowDate">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="dateRangeSelector = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.dateRangeSelector.save(dates); changeRangeSelector()">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </div>
                <div class="filter-floor left">
                    <v-select v-model="selectedFloor" :items="GET_VENUE_FLOORS" item-text="name" item-value="value.id" label="Select a floor" v-on:change="changeFloor()"></v-select>
                </div>
                <div class="filter-floor left">
                    <v-btn class="primary" @click="close()">Close</v-btn>
                </div>
            </div>
            <div class="filter-area map-bar rangebar">
                <v-slider v-model="selectedRange" :min="GET_DRANGE.min" :max="GET_DRANGE.max" :thumb-size="GET_DRANGE.size" step="1" tick-size="2" ticks="always" class="slider-range" color="#ffc04c" thumb-color="#ffc04c" vertical thumb-label v-on:change="changeDRange()">
                    <template v-slot:thumb-label="{ value }">
                        {{ GET_DRANGE.labels[value-1].split(' ')[GET_DRANGE.type == "Hour" ? 1 : 0] }}
                    </template>
                </v-slider>
            </div>
            <loader v-show="GET_LOADER_STATUS.wirelessHeat"></loader>
            <mapview :geoMap="GET_VENUE_MAP" :heatMap="GET_WIRELESS_HEATMAP" :jumpTo="GET_JUMPER"></mapview>
        </div>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Mapview from '../shared/mapview'
import Loader from '../shared/loader'

export default {
    components: { Mapview, Loader },
    data() {
        return {
            selectedFloor: null,
            selectedDuration: null,
            selectedRange: 0,
            dates: [],
            dateRangeSelector: false,
            selectRangeBox: false,
            nowDate: new Date().toISOString().slice(0,10),
        };
    },
    computed:{
        ...mapGetters([
        'CURRENT_VENUE', 
        'GET_MODAL_STATUS', 
        'IS_VENUE_SELECTED', 
        'WIRELESS_HEATMAP_READY', 
        'GET_DRANGE', 
        'GET_DURATION', 
        'GET_VENUE_FLOORS', 
        'GET_JUMPER', 
        'GET_VENUE_MAP', 
        'GET_SELECTED_FLOOR', 
        'GET_WIRELESS_HEATMAP',
        'GET_SELECTED_DURATION',
        'GET_LOADER_STATUS']),

        dateRangeText () {
            return this.dates.join(' - ')
        },
    },
    watch: {
        CURRENT_VENUE(newValue, oldValue){
            this.$store.dispatch('CREATE_HEATMAP', null, { root: true }).then((data) => {
                this.selectedFloor = this.GET_SELECTED_FLOOR
                this.selectedDuration = this.GET_SELECTED_DURATION.id
                this.$store.commit('SET_WHEATMAP_READY', true)
            })
        },
        selectedDuration(newValue, oldValue){
            if(this.GET_SELECTED_DURATION.id == 4){
                this.selectRangeBox = !this.selectRangeBox
            }else{
                this.selectRangeBox = false
            }
        },
    },
    methods: {
        changeDuration(){
            let _duration = this.GET_DURATION.find(x => x.id == this.selectedDuration)
            this.$store.commit('CHANGE_HEAT_DURATION', _duration)
            this.$store.dispatch('CREATE_HEAT_RANGE')
        },
        changeFloor(){
            this.$store.commit('SET_SELECTED_FLOOR', this.selectedFloor)
            this.$store.dispatch('FIND_VENUE_MAP', this.selectedFloor) 
            this.changeDRange()
        },
        changeDRange(){
            let _selected = this.GET_DRANGE.labels[this.selectedRange-1]
            this.$store.commit('SET_LOADER_STATUS', { target: "wirelessHeat", value: true})        
            this.$store.dispatch('CREATE_WIRELESS_HEATMAP', _selected, { root: true }).then((data) => {
                this.$store.commit('SET_LOADER_STATUS', { target: "wirelessHeat", value: false}) 
            })
        },
        changeRangeSelector(){
            this.$store.dispatch('CHANGE_DURATION_RANGE', this.dates, { root: true })
        },
        close() {
            this.$store.commit('SET_MODAL_STATUS', { target: "wheatmap", value: false})
            this.$store.dispatch('GENERATE_DATA')
        },
    },
    mounted() {
        if(this.IS_VENUE_SELECTED){
            this.$store.dispatch('CREATE_HEATMAP', null, { root: true }).then((data) => {
                this.selectedFloor = this.GET_SELECTED_FLOOR
                this.selectedDuration = this.GET_SELECTED_DURATION.id
                this.$store.commit('SET_WHEATMAP_READY', true)
            })
        }
    },
}
</script>
<style scoped>
  .slider-range >>> .v-slider {
    height: 500px;
  }
</style>