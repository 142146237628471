import fetchRQ from '../../../plugins/data-request'
import fetchGetRQ from '../../../plugins/data-get-requests'

export default {
    state: {
        date_visibility: true,
        wireless_map_ready: false,
        wireless_counts: [],
        timer: {}
    },
    getters : {
        IS_WIRELESSMAP_READY: state => {
            return state.wireless_map_ready
        },
        GET_WIRELESS_DEVICES: state => {
            return state.wireless_counts
        },
        GET_TIMER: state => {
            return state.timer
        }
    },
    actions: {
        INIT_WIRELESS_MAP: async (context) =>{
            await context.dispatch('CREATE_JUMPER', 18, { root: true })
            await context.dispatch('FIND_VENUE_MAP', null, { root: true })
            await context.dispatch('FIND_VENUE_FLOORS', null, { root: true })
        },
        GENERATE_DATA: async (context) => {

            let _venue = context.rootGetters.CURRENT_VENUE.id
            let _floor = context.rootGetters.GET_SELECTED_FLOOR

            clearInterval(context.state.timer)

            context.state.timer = setInterval(() => {
                context.dispatch('FIND_REALTIME_DATA', {venue: _venue, floor: _floor}, { root: true })
            }, 3000)
        },
        FIND_REALTIME_DATA: async(context, data) => {

            let records = []
            let response = await fetchGetRQ(`counter/wireless/live/${data.venue}/${data.floor}`)

            if(response.valid && response.message.isValid){

                let _id = 0
                
                response.message.data.forEach((record) => {
                    records.push({id: _id, value: [record.coordinates.lng, record.coordinates.lat]})
                    _id++
                })
            }

            context.commit('SET_WIRELESS_DEVICES', records)

        }
    }, 
    mutations: {
        SET_WIRELESSMAP_READY: (state, value) => {
            state.wireless_map_ready = value
        },
        SET_WIRELESS_DEVICES: (state, value) => {
            state.wireless_counts = value
        }
    }
}