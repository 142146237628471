<template>
    <v-dialog v-model="GET_MODAL_STATUS.winsights" fullscreen hide-overlay transition="dialog-bottom-transition" @keydown.esc="close()" @keydown.enter="close()">
        <div class="dialog-box-inner">
            <venue-selection-box v-if="!IS_VENUE_SELECTED"></venue-selection-box>
            <div class="table-list" v-else>
                <div class="inner">
                    <div class="table-filter">
                        <div class="table-filter-item">
                            <v-container fluid>
                                <v-select v-model="selectedZones" :items="GET_LIST_ZONES_VALUES" item-text="name" item-value="id" label="Select Zones" multiple>
                                    <template v-slot:prepend-item>
                                    <v-list-item ripple @click="toggle">
                                        <v-list-item-action>
                                        <v-icon :color="selectedZones.length > 0 ? 'indigo darken-4' : ''"> {{ icon }} </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                        <v-list-item-title>Select All</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0"><span>{{ item.name }}</span></v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">(+{{ selectedZones.length - 1 }} others)</span>
                                    </template>
                                </v-select>
                            </v-container>
                        </div>
                        <div class="table-filter-item">
                            <v-menu v-model="dateSelector" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="date" label="Select a date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="date" @input="dateSelector = false" :max="maxDate"></v-date-picker>
                            </v-menu>
                        </div>
                        <div class="table-filter-item">
                            <v-select v-model="selectedPeriod" :items="GET_WINSIGHTS_PERIOD" item-text="name" item-value="id" label="Select a period" @change="updateSearch"></v-select>
                        </div>
                        <div  class="table-filter-item middle">
                            <v-btn class="primary" @click="close()">Close</v-btn>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart-box" v-if="GET_LIST_WIRELESS_DATA_READY">
                <div class="">
                    <highcharts :options="GET_CHART_DATA"></highcharts>
                </div>
            </div>
            <div class="list-box" v-if="GET_LIST_WIRELESS_DATA_READY">
                <template>
                    <v-data-table :headers="GET_LIST_WIRELESS_DATA.headers" :items="GET_LIST_WIRELESS_DATA.values" :items-per-page="10">
                        <template v-slot:item.action="{item}">
                            <!--<v-btn class="mr-1" @click="AddToChart(item)" v-if=!item.selected><v-icon small>mdi-check-circle-outline</v-icon></v-btn>
                            <v-btn class="mr-1" @click="RemoveFromChart(item)" v-if=item.selected><v-icon small>mdi-check-circle</v-icon></v-btn>-->
                            <v-btn class="mr-1" @click="DeleteSearch(item)"><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
                        </template>
                    </v-data-table>
                </template>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import VenueSelectionBox from '../shared/venue-selection-box'

export default {
    components: { VenueSelectionBox },
    data() {
        return {
            selectedPeriod: 'hour',
            selectedZones: [],
            dateSelector: false,
            date: new Date().toISOString().substr(0, 10),
            maxDate: new Date().toISOString().substr(0, 10),        
        };
    },
    computed:{
        ...mapGetters(['CURRENT_VENUE', 'IS_VENUE_SELECTED', 'GET_LIST_WIRELESS_DATA_READY', 'GET_LIST_WIRELESS_DATA', 'GET_MODAL_STATUS', 'GET_LIST_ZONES_VALUES', 'GET_WINSIGHTS_PERIOD', 'GET_CHART_DATA']),
    
        selectAll () {
            return this.selectedZones.length === this.GET_LIST_ZONES_VALUES.length
        },
        selectSome () {
            return this.selectedZones.length > 0 && !this.selectAll
        },
        icon () {
            if (this.selectAll) return 'mdi-close-box'
            if (this.selectSome) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    watch: {
        CURRENT_VENUE(newValue, oldValue){
            this.$store.dispatch('PREPARE_WINSIGHTS', this.devices)
        },
        selectedZones(newValue, oldValue){
            this.updateSearch()
        },
        date(newValue, oldValue){
            this.updateSearch()
        }
    },
    methods: {
        AddToChart(item){},
        RemoveFromChart(item){},
        DeleteSearch(item){
            this.selectedZones = this.selectedZones.filter(function(_item) {
                return _item !== item.id
            })
            this.$store.dispatch('DELETE_ITEM_CHART', item)
        },

        updateSearch(){
            let data = {
                zones: this.selectedZones,
                period: this.selectedPeriod,
                selectedDate: this.date 
            }

            if(data != undefined && data.zones != null && data.period != null){
                this.$store.dispatch('PREPARE_WINSIGHTS_DATA', data)
            }           
        },
        toggle () {
            this.$nextTick(() => {
            if (this.selectAll) {
                this.selectedZones = []
            } else {
                this.selectedZones = this.GET_LIST_ZONES_VALUES.map(x => x.id)
            }
            })
        },

        close() {
            this.$store.commit('SET_MODAL_STATUS', { target: "winsights", value: false})
            this.$store.dispatch('GENERATE_DATA')   
        },
    },
    mounted() {
        if(this.IS_VENUE_SELECTED){
            this.$store.dispatch('PREPARE_WINSIGHTS', this.devices)
        }
    },
}
</script>