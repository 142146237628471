<template>
    <div class="view-map wireless-counters">
        <venue-selection-box v-if="!IS_VENUE_SELECTED"></venue-selection-box>
        <div class="map-inner" v-else>
            <div class="filter-area map-left" v-if="IS_WIRELESSMAP_READY">
                <div class="field-line">
                    <div class="field-title">Venue Floor</div>
                    <div class="field-box">
                        <v-select v-model="selectedFloor" :items="GET_VENUE_FLOORS" item-text="name" item-value="value.id" v-on:change="ChangeFloor()"></v-select>
                    </div>
                </div>
                <div class="field-line">
                    <div class="field-title"></div>
                    <div class="field-box">
                      <ul class="selection-menu">
                        <li class="selected"><span>Realtime</span></li>
                        <li @click="showInsights()"><span>Insights</span></li>
                        <li @click="showHeatmap()"><span>Heatmap</span></li>
                      </ul>
                    </div>
                </div>
            </div>
            <mapview :geoMap="GET_VENUE_MAP" :jumpTo="GET_JUMPER" :wireless="GET_WIRELESS_DEVICES"></mapview>
        </div>
        <insights v-model="GET_MODAL_STATUS.winsights"></insights>
        <heatmap v-model="GET_MODAL_STATUS.wheatmap"></heatmap>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
import Mapview from '../shared/mapview'
import Insights from '../wireless-counters/insights'
import Heatmap from '../wireless-counters/heatmap'
import VenueSelectionBox from '../shared/venue-selection-box'

export default {
  components: { Mapview, Insights, Heatmap, VenueSelectionBox },
  data: () => ({
    selectedFloor: null,
  }),
  computed:{
      ...mapGetters(['CURRENT_VENUE', 'IS_WIRELESSMAP_READY', 'GET_JUMPER', 'GET_VENUE_MAP', 'GET_VENUE_FLOORS', 'IS_VENUE_SELECTED', 'GET_SELECTED_FLOOR', 'GET_WIRELESS_DEVICES', 'GET_TIMER', 'GET_MODAL_STATUS'])
  },
  watch: {
    CURRENT_VENUE(newValue, oldValue){
      this.$store.dispatch('INIT_WIRELESS_MAP').then((response) => {    
        this.selectedFloor = this.GET_SELECTED_FLOOR
        this.$store.commit('SET_WIRELESSMAP_READY', true)
        this.GenerateData()
      })
    }
  },
  mounted() { 
    if(this.IS_VENUE_SELECTED){
      this.$store.dispatch('INIT_WIRELESS_MAP').then((response) => {
        this.selectedFloor = this.GET_SELECTED_FLOOR
        this.$store.commit('SET_WIRELESSMAP_READY', true)
        this.GenerateData()
      })
    }
  },
  methods: {
    GenerateData(){
        this.$store.dispatch('GENERATE_DATA')
    },
    ChangeFloor(){
      this.$store.commit('SET_SELECTED_FLOOR', this.selectedFloor)
      this.$store.dispatch('FIND_VENUE_MAP', this.selectedFloor)
      this.GenerateData()
    },
    showInsights(){
      this.$store.commit('SET_MODAL_STATUS', { target: "winsights", value: true})
      clearInterval(this.GET_TIMER)
    },
    showHeatmap(){
      this.$store.commit('SET_MODAL_STATUS', { target: "wheatmap", value: true})
      clearInterval(this.GET_TIMER)
    }
  },
  beforeDestroy() {
    clearInterval(this.GET_TIMER);
  }

};
</script>